<template>
  <div>
    <!-- 表格 -->
    <a-table
      :dataSource="dataSource"
      :columns="columns"
      :pagination="false"
      :loading="tableLoading"
      :scroll="scroll"
      :childrenColumnName="childrenColumnName"
      rowKey="id"
    >
      <!-- column表头标题  text展示文字  record后端返回数据  -->
      <template #bodyCell="{ column, text, record }">
        <slot
          :column="column"
          :text="text"
          :record="record"
        ></slot>
      </template>
    </a-table>
    <!-- 分页 -->
    <a-pagination
      class="paginaton"
      :current="paginationData.page"
      :pageSize="paginationData.pageSize"
      :total="dataTotal"
      @change="changePage"
      @showSizeChange="changePage"
    />
  </div>
</template>

<script>
import { ref, reactive } from '@vue/reactivity'
import { onMounted } from '@vue/runtime-core'
export default {
  props: {
    columns: {
      type: Object
    },
    getData: {
      type: Function
    },
    subHeight: { type: Number },
    childrenColumnName: { type: String }
  },
  setup(props) {
    onMounted(() => {
      // 监听页面大小改变
      window.onresize = () => {
        scroll.y = document.body.offsetHeight - props.subHeight
      }
    })

    // 表格高度
    const scroll = reactive({
      y: document.body.offsetHeight - props.subHeight
    })
    // 分页数据
    const paginationData = reactive({
      // 当前页
      page: 1,
      // 每页展示条数
      pageSize: 20
    })
    const dataTotal = ref() // 总数据条数
    const dataSource = ref() // 表格数据
    const tableLoading = ref(false) // 表格等待状态
    // 修改分页数据调用方法
    const changePage = (page, pageSize) => {
      paginationData.page = page
      paginationData.pageSize = pageSize
      getTableData()
    }
    // 查询数据方法
    const getTableData = () => {
      tableLoading.value = true
      props.getData(paginationData).then((response) => {
        const { data, total } = response
        dataSource.value = data // 修改表格展示数据
        dataTotal.value = total // 修改数据总条数
        tableLoading.value = false
      })
    }

    return {
      scroll,
      paginationData,
      dataTotal,
      dataSource,
      tableLoading,
      getTableData,
      changePage
    }
  }
}
</script>

<style>
.ant-table-body,
.ant-table-header {
  overflow-y: auto !important;
}

.paginaton {
  float: right;
  margin: 8px 0px;
}
</style>
