
import Table from '../components/Table.vue'
import {
  SELECTARTICLE,
  DELETEARTICLE,
  UPDATEISISSUE,
  UPDATEISTOP,
  SELECTARTICLEBYID
} from '../api/api'
import { reactive, ref } from '@vue/reactivity'
import { onMounted } from '@vue/runtime-core'
import TEditor from '@/components/TEditor.vue'

export default {
  components: {
    Table,
    TEditor
  },
  setup() {
    const table = ref(null)
    onMounted(() => {
      table.value.getTableData()
      coverPhotoUrl.value =
        'https://yedeqin-blog-1257421454.cos.ap-nanjing.myqcloud.com/'
    })
    // 表格字段
    const columns = ref([
      {
        title: 'ID',
        dataIndex: 'id',
        key: 'id',
        width: 50,
        align: 'center'
      },
      {
        title: '文章标题',
        dataIndex: 'title',
        key: 'title',
        width: 250
      },
      {
        title: '文章描述',
        dataIndex: 'subtitle',
        key: 'subtitle',
        width: 300,
        ellipsis: true
      },
      {
        title: '文章分类',
        dataIndex: 'category_name',
        key: 'category_name',
        width: 100,
        align: 'center'
      },
      {
        title: '文章标签',
        dataIndex: 'tag_name',
        key: 'tag_name',
        width: 300,
        align: 'center'
      },
      {
        title: '封面图片',
        dataIndex: 'cover_photo',
        key: 'cover_photo',
        width: 100,
        align: 'center'
      },
      {
        title: '文章内容',
        dataIndex: 'content',
        key: 'content',
        width: 100,
        align: 'center'
      },
      {
        title: '是否发布',
        dataIndex: 'is_issue',
        key: 'is_issue',
        width: 90,
        align: 'center'
      },
      {
        title: '是否置顶',
        dataIndex: 'is_top',
        key: 'is_top',
        width: 90,
        align: 'center'
      },
      {
        title: '更新时间',
        dataIndex: 'modified_time',
        key: 'modified_time',
        width: 180,
        align: 'center'
      },
      {
        title: '创建时间',
        dataIndex: 'create_time',
        key: 'create_time',
        width: 180,
        align: 'center'
      },
      {
        title: '操作',
        dataIndex: 'action',
        isEdit: false,
        key: 'action',
        width: 120,
        align: 'center',
        fixed: 'right'
      }
    ])
    // 查询数据表单
    const selectArticleFrom = reactive({
      title: ''
    })
    // 查询文章列表方法
    const selectArticle = (pageData) => {
      return new Promise((resolve, reject) => {
        SELECTARTICLE(Object.assign(pageData, selectArticleFrom))
          .then((response) => {
            resolve(response.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    }
    // 删除文章方法
    const deleteArticle = (id) => {
      console.log(id)
      DELETEARTICLE({ id }).then((response) => {
        if (response.code === 0) {
          table.value.getTableData()
        }
      })
    }
    // 编辑文章
    // 携带数据跳转至新增文章
    const contentVisible = ref(false) // 文章详情弹窗展示状态
    const articleContent = ref() // 文章详情内容
    // 查看文章详情
    const lookcontentDetails = async (id) => {
      await SELECTARTICLEBYID({ id: id }).then((response) => {
        if (response.code === 0) {
          articleContent.value = response.data.content
        }
      })
      contentVisible.value = true
    }
    // 关闭文章详情弹窗
    const handleCancel = () => {
      contentVisible.value = false
    }
    const coverPhotoUrl = ref()
    const tagColor = ref([
      'green',
      'blue',
      'pink',
      'red',
      'orange',
      'purple',
      'cyan'
    ])
    const setColor = () => {
      return tagColor.value[Math.round(Math.random() * 6)]
    }
    // 更新发布状态
    const toRelease = (issue, id) => {
      if (issue) {
        issue = 1
      } else {
        issue = 0
      }
      UPDATEISISSUE({ is_issue: issue, id: id }).then((response) => {
        if (response.code === 0) {
          table.value.getTableData()
        }
      })
    }
    // 更新置顶状态
    const toTop = (top, id) => {
      if (top) {
        top = 1
      } else {
        top = 0
      }
      UPDATEISTOP({ is_top: top, id: id }).then((response) => {
        if (response.code === 0) {
          table.value.getTableData()
        }
      })
    }
    return {
      table,
      columns,
      selectArticleFrom,
      selectArticle,
      deleteArticle,
      contentVisible,
      articleContent,
      lookcontentDetails,
      handleCancel,
      coverPhotoUrl,
      setColor,
      toRelease,
      toTop
    }
  }
}
